<template>
  <v-card flat>
    <v-card-text class="pt-2">
      <v-row class="mt-2">
        <v-col
          md="6"
          cols="12"
        >
          <!-- ปุ่มเลือกสินค้า -->
          <v-select
            dense
            outlined
            hide-details
            label="สินค้า"
            :items="product"
            class="selected"
          ></v-select>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="d-flex justify-end display__responsive"
        >
          <!-- ปุ่มนำเข้าสินค้า -->
          <div class="text-center">
            <template>
              <v-row justify="space-around">
                <v-col cols="auto">
                  <v-dialog
                    transition="dialog-top-transition"
                    width="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        outlined
                        v-bind="attrs"
                        class="me-2"
                        v-on="on"
                      >
                        นำเข้าสินค้า
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar
                          color="primary"
                          dark
                        >
                          นำเข้าสินค้า
                        </v-toolbar>
                        <!-- tabs -->
                        <v-tabs
                          v-model="tab"
                          show-arrows
                        >
                          <v-tab
                            v-for="tab in tabs"
                            :key="tab.icon"
                          >
                            <v-icon
                              size="20"
                              class="me-3"
                            >
                              {{ tab.icon }}
                            </v-icon>
                            <span>{{ tab.title }}</span>
                          </v-tab>
                        </v-tabs>
                        <!-- tabs item -->
                        <v-tabs-items v-model="tab">
                          <v-tab-item>
                            <UploadImProduct />
                            <div class="content__Bottom">
                              <div>
                                <v-select
                                  dense
                                  outlined
                                  label="อัลบั้มสินค้า"
                                  :items="productSelected"
                                  class="product__selected"
                                ></v-select>
                              </div>
                              <div>
                                <v-btn
                                  color="primary"
                                  @click="dialog.value = false"
                                >
                                  นำเข้าสินค้า
                                </v-btn>
                              </div>
                            </div>
                          </v-tab-item>

                          <v-tab-item>
                            <UploadFileProductVue />
                          </v-tab-item>
                        </v-tabs-items>
                        <v-card-actions class="justify-end">
                          <v-btn
                            text
                            @click="dialog.value = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
          </div>
          <!-- ปุ้มเพิ่มสินค้า -->
          <div class="text-center">
            <template>
              <v-row justify="space-around">
                <v-col cols="auto">
                  <v-dialog
                    transition="dialog-top-transition"
                    width="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        เพิ่มสินค้า
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar
                          color="primary"
                          dark
                        >
                          เพิ่มสินค้า
                        </v-toolbar>
                        <AddProduct />
                        <!-- <v-card-text> </v-card-text> -->
                        <v-card-actions class="d-flex justify-end mt-8">
                          <!-- button save -->
                          <v-btn
                            color="error"
                            outlined
                            class="me-2"
                            @click="dialog.value = false"
                          >
                            ยกเลิก
                            <v-icon class="ml-2">
                              {{ icons.mdiCloseThick }}
                            </v-icon>
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="dialog.value = false"
                          >
                            บันทึก
                            <v-icon class="ml-2">
                              {{ icons.mdiContentSave }}
                            </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="8"
          cols="12"
          class="pt-1 d-flex"
        >
          <v-text-field
            :prepend-inner-icon="icons.mdiMagnify"
            label="Enter เพื่อค้นหาสินค้า"
            type="text"
            outlined
            dense
            hide-details
            placeholder="Enter เพื่อค้นหาสินค้า"
            class="search__product"
          ></v-text-field>
          <v-select
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            :items="filter"
            class="select__filter"
          ></v-select>
          <v-select
            dense
            outlined
            hide-details
            label="เรียงตาม"
            :items="sort"
          ></v-select>
        </v-col>
        <v-col
          md="4"
          cols="12"
          class="pt-1 mb-4 wrapper__download"
        >
          <v-select
            dense
            hide-details
            outlined
            label="ดาวน์โหลด"
            :items="download"
            class="download"
          ></v-select>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-if="!slectedProduct"
                class="text_table"
              >
                <input
                  id="vehicle1"
                  type="checkbox"
                  name="vehicle1"
                  value="Bike"
                  class="checkbox"
                  @click="clickSelected"
                />
                สินค้า
              </th>
              <th v-if="slectedProduct">
                <div class="d-flex">
                  <div class="contentSelect">
                    <v-icon
                      size="20"
                      class="me-1 icons_style"
                      @click="clickCancleSelected"
                    >
                      {{
                        icons.mdiMinus
                      }}
                    </v-icon>
                    <span>เลือก {{ productSelected }} รายการ</span>
                  </div>
                  <v-dialog
                    transition="dialog-top-transition"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-btn text v-bind="attrs" v-on="on" class="me-2">ย้ายอัลบั้ม</v-btn> -->
                      <button
                        v-bind="attrs"
                        class="btn_style"
                        v-on="on"
                      >
                        ย้ายอัลบั้ม
                      </button>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="d-flex align-center pt-4">
                            <span class="font-weight-bold">ย้าย (1) สินค้าไปที่อัลบั้ม : </span>
                            <v-select
                              dense
                              outlined
                              hide-details
                              label="สินค้า"
                              :items="product"
                              class="me-2 ms-2 selected"
                            ></v-select>
                            <v-btn
                              color="primary"
                              @click="dialog.value = false"
                            >
                              บันทึก
                            </v-btn>
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            text
                            @click="dialog.value = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <v-dialog
                    transition="dialog-top-transition"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-btn color="error" outlined v-bind="attrs" v-on="on" class="me-2">ลบ</v-btn> -->
                      <button
                        v-bind="attrs"
                        class="btn_delete"
                        v-on="on"
                      >
                        ลบ
                      </button>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="text-center pt-4">
                            <v-icon
                              size="100"
                              color="warning"
                            >
                              {{ icons.mdiAlertCircle }}
                            </v-icon>
                            <h2 class="mt-2 mb-2">
                              ยืนยันการลบสินค้า
                            </h2>
                            <p class="font-weight-bold">
                              ลบสินค้าจำนวน 1 รายการ ออกจากอัลบั้มสินค้า
                            </p>
                          </div>
                          <div class="d-flex justify-center mb-3">
                            <v-btn
                              color="error"
                              outlined
                              class="me-2 btn_width"
                              @click="dialog.value = false"
                            >
                              ยกเลิก
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="btn_width"
                              @click="dialog.value = false"
                            >
                              บันทึก
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-dialog>
                </div>
              </th>
              <th class="text-center">
                รหัส
              </th>
              <th class="text-center">
                ราคา
              </th>
              <th class="text-center">
                สต๊อก
              </th>
              <th class="text-center">
                ติดจอง
              </th>
              <th class="text-center">
                พร้อมขาย
              </th>
              <th class="text-center">
                แสดงบนร้าน
              </th>
              <th class="text-center">
                <v-tooltip
                  v-if="!switchIcon"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="20"
                      class="me-3 icon__style"
                      v-bind="attrs"
                      @click="clickSwitchIcon"
                      v-on="on"
                    >
                      {{ icons.mdiArrowExpandAll }}
                    </v-icon>
                  </template>
                  <span>ขยายเพื่อแสดงแบบสินค้า</span>
                </v-tooltip>

                <v-tooltip
                  v-if="switchIcon"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="switchIcon"
                      size="20"
                      class="me-3 icon__style"
                      v-bind="attrs"
                      @click="clickSwitchIcon"
                      v-on="on"
                    >
                      {{ icons.mdiArrowCollapse }}
                    </v-icon>
                  </template>
                  <span>ย่อเพื่อซ่อนแบบสินค้า</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in products"
              :key="item.product_id"
            >
              <v-dialog
                v-if="showContent"
                v-model="showContent"
                transition="dialog-top-transition"
                width="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    เพิ่มสินค้า
                  </v-btn>
                </template>
                <template>
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >
                      รายการแบบสินค้า
                    </v-toolbar>
                    <EditStock />
                    <!-- <v-card-text> </v-card-text> -->
                    <!-- <v-card-actions class="d-flex justify-end mt-8">
                      <v-btn color="info" class="me-2" @click="dialog.value = false">
                        ปรับสต๊อก
                      </v-btn>
                      <v-btn color="primary" @click="dialog.value = false">
                        แก้ไขสินค้า
                      </v-btn>
                    </v-card-actions> -->
                  </v-card>
                </template>
              </v-dialog>
              <td>
                <div class="d-flex">
                  <input
                    id="vehicle1"
                    type="checkbox"
                    name="vehicle1"
                    :value="item.product_id"
                    class="checkbox"
                    @click="clickSelected"
                  />
                  <span
                    class="mb-0 textClick"
                    @click="clickContent"
                  >{{ item.product_name }}</span>
                </div>
              </td>
              <td
                class="text-center"
                @click="clickContent"
              >
                {{ item.product_name }}
              </td>
              <td
                class="text-center"
                @click="clickContent"
              >
                {{ item.product_costs }}
              </td>
              <td
                class="text-center"
                @click="clickContent"
              >
                {{ 0 }}
              </td>
              <td
                class="text-center"
                @click="clickContent"
              >
                {{ 0 }}
              </td>
              <td
                class="text-center"
                @click="clickContent"
              >
                {{ item.redySell }}
              </td>
              <td class="text-center warpper__toggle">
                <v-switch class="style__switch"></v-switch>
              </td>
              <td class="text-center">
                <div class="text-center">
                  <template>
                    <v-row justify="space-around">
                      <v-col cols="auto">
                        <v-dialog
                          transition="dialog-top-transition"
                          width="1000"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              ปรับสต๊อก
                            </v-btn>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar
                                color="primary"
                                dark
                              >
                                รายการแบบสินค้า
                              </v-toolbar>
                              <v-card-text>
                                <v-row class="mt-4">
                                  <v-col
                                    md="2"
                                    cols="12"
                                  >
                                    <v-img
                                      :aspect-ratio="16 / 9"
                                      src="../../../assets/images/other/laptop-2298286.svg"
                                      class="image__product"
                                    ></v-img>
                                  </v-col>
                                  <v-col
                                    md="6"
                                    cols="12"
                                  >
                                    <div>
                                      <p>(สินค้าไม่มีชื่อ)</p>
                                      <v-row>
                                        <v-col
                                          md="3"
                                          sm="3"
                                          cols="12"
                                        >
                                          <v-card class="box__stock">
                                            <p>สต๊อก</p>
                                            <p>{{ item.stock }}</p>
                                          </v-card>
                                        </v-col>
                                        <v-col
                                          md="3"
                                          sm="3"
                                          cols="12"
                                        >
                                          <v-card class="box__stock">
                                            <p>ติดจอง</p>
                                            <p>{{ item.reserve }}</p>
                                          </v-card>
                                        </v-col>
                                        <v-col
                                          md="3"
                                          sm="3"
                                          cols="12"
                                        >
                                          <v-card class="box__stock">
                                            <p>พร้อมขาย</p>
                                            <p>{{ item.redySell }}</p>
                                          </v-card>
                                        </v-col>
                                        <v-col
                                          md="3"
                                          sm="3"
                                          cols="12"
                                        >
                                          <v-card class="box__stock">
                                            <p>ส่งแล้ว</p>
                                            <p>{{ item.send }}</p>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-simple-table class="mt-4">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-uppercase">
                                          รหัส
                                        </th>
                                        <th class="text-center text-uppercase">
                                          แบบสินค้า
                                        </th>
                                        <th class="text-center text-uppercase">
                                          ราคา
                                        </th>
                                        <th class="text-center text-uppercase">
                                          สต๊อก
                                        </th>
                                        <th class="text-center text-uppercase">
                                          +เพิ่มสต๊อก
                                        </th>
                                        <th class="text-center text-uppercase">
                                          หมายเหตุ
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in desserts"
                                        :key="item.dessert"
                                      >
                                        <td>{{ item.sku }}</td>
                                        <td class="text-center">
                                          ไม่ระบุแบบ
                                        </td>
                                        <td class="text-center">
                                          {{ item.price }}
                                        </td>

                                        <td class="text-center">
                                          <v-text-field
                                            id="firstname"
                                            outlined
                                            dense
                                            hide-details
                                          ></v-text-field>
                                        </td>
                                        <td class="text-center">
                                          <v-text-field
                                            id="firstname"
                                            outlined
                                            dense
                                            hide-details
                                          ></v-text-field>
                                        </td>
                                        <td class="text-center">
                                          <v-text-field
                                            id="firstname"
                                            outlined
                                            dense
                                            hide-details
                                          ></v-text-field>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                                <div class="d-flex justify-end mt-3">
                                  <v-btn
                                    color="error"
                                    outlined
                                    class="me-3"
                                    @click="dialog.value = false"
                                  >
                                    <span class="d-sm-block">ยกเลิก</span>
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    @click="dialog.value = false"
                                  >
                                    <span class="d-sm-block">บันทึก</span>
                                  </v-btn>
                                </div>
                              </v-card-text>
                              <!-- <v-card-actions class="justify-end">
                                <v-btn text @click="dialog.value = false">Close</v-btn>
                              </v-card-actions> -->
                            </v-card>
                          </template>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <hr class="mt-4" />
      <div class="mt-4 warpper__contentBottom">
        <p>แสดง 1 ถึง {{ page }} จาก {{ leghtPage }} ผลลัพธ์</p>
        <div class="pagination_responsive">
          <!-- <a @click="clickFirstPage">หน้าแรก</a> -->
          <v-pagination
            v-model="page"
            :length="leghtPage"
            :total-visible="5"
          ></v-pagination>
          <!-- <a @click="clickLastPage">หน้าสุดท้าย</a> -->
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiArrowExpandAll,
  mdiCloudUploadOutline,
  mdiCloseThick,
  mdiContentSave,
  mdiCartOutline,
  mdiFile,
  mdiImage,
  mdiChevronLeft,
  mdiArrowCollapse,
  mdiMinus,
  mdiAlertCircle,
} from '@mdi/js'
import UploadImProduct from './UploadImgProduct.vue'
import UploadFileProductVue from './UploadFileProduct.vue'
import AddProduct from './AddProductModal.vue'
import EditStock from './editStock.vue'
import config from '../../../config'
import instance from '../../../axios.service'

export default {
  components: {
    UploadImProduct,
    UploadFileProductVue,
    AddProduct,
    EditStock,
  },
  setup() {
    const tab = ref('')
    const product = ['สินค้าทั้งหมด', 'ทั่วไป']
    const productSelected = ['ทั่วไป', '+ เพิ่มอัลบั้มสินค้าใหม่']
    const download = ['ดาวโหลดไฟล์ (csv)']
    const filter = ['สินค้าทั้งหมด', 'สินค้าที่ไม่แสดงบนหน้าร้าน', 'สินค้าที่แสดงบนหน้าร้าน']
    const showContent = false

    // tabs
    const tabs = [
      { title: 'อัพโหลดรูป', icon: mdiImage },
      { title: 'อัพโหลดสินค้าด้วย CSV', icon: mdiFile },
    ]
    const sort = [
      'ขายได้มากที่สุด',
      'ขายได้น้อยที่สุด',
      'สต๊อกเหลือมากที่สุด',
      'สต๊อกเหลือน้อยที่สุด',
      'ติดจองมากที่สุด',
      'ติดจองน้อยที่สุด',
    ]
    const desserts = [
      {
        dessert: 'Frozen Yogurt',
        sku: 159,
        price: 99,
        stock: 24,
        reserve: 0,
        redySell: 24,
        send: 3,
      },
      {
        dessert: 'Ice cream sandwich',
        sku: 237,
        price: 189,
        stock: 9,
        reserve: 4,
        redySell: 5,
        send: 6,
      },
      {
        dessert: 'Eclair',
        sku: 262,
        price: 239,
        stock: 1,
        reserve: 4,
        redySell: 1,
        send: 2,
      },
      {
        dessert: 'Cupcake',
        sku: 305,
        price: 199,
        stock: 5,
        reserve: 4,
        redySell: 4,
        send: 9,
      },
      {
        dessert: 'Gingerbread',
        sku: 356,
        price: 99,
        stock: 21,
        reserve: 0,
        redySell: 21,
        send: 4,
      },
    ]

    return {
      products: [],
      tab,
      product,
      tabs,
      download,
      desserts,
      filter,
      sort,
      productSelected,
      showContent,
      icons: {
        mdiMagnify,
        mdiArrowExpandAll,
        mdiCloudUploadOutline,
        mdiCloseThick,
        mdiContentSave,
        mdiFile,
        mdiImage,
        mdiCartOutline,
        mdiChevronLeft,
        mdiArrowCollapse,
        mdiMinus,
        mdiAlertCircle,
      },
    }
  },
  data: () => ({
    items: ['Gaming', 'Programming', 'Vue', 'Vuetify'],
    isPlaying: false,
    page: 1,
    leghtPage: 10,
    dialog: false,
    switchIcon: false,
    slectedProduct: false,
    productSelected: 4,
  }),
  mounted() {
    this.getProduct()
  },
  methods: {
    toggleIsPlaying() {
      this.isPlaying = !this.isPlaying
    },
    clickFirstPage() {
      this.page = 1
    },
    clickLastPage() {
      this.page = this.leghtPage
    },
    clickContent() {
      this.showContent = true
    },
    clickSwitchIcon() {
      this.switchIcon = !this.switchIcon
      this.clickSelected()
    },
    clickSelected() {
      if (!this.slectedProduct) {
        this.slectedProduct = true
      } else {
        this.slectedProduct = false
      }
    },
    clickCancleSelected() {
      this.slectedProduct = false
    },
    getProduct() {
      instance
        .get(`${`${config.baseUrl}/product?search=`}`)
        .then(res => {
          // console.log(res)
          if (res.status === 200) {
            this.products = res.data.data
            
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
            // eslint-disable-next-line no-restricted-globals
            location.reload()
          }
        })
        .catch(error => {
          console.log(error)
          this.errorPage(error)
        })
    },
  },
}
</script>

<style scoped>
td {
  cursor: pointer;
}
.selected {
  width: 30%;
}
.search__product {
  width: 60%;
}
.filter {
  width: 10%;
  margin: 0 5px;
}
.select__filter {
  margin: 0 0.5rem;
}
.wrapper__download {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.download {
  width: 60%;
}
.checkbox {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}
.icon__style {
  cursor: pointer;
}
.warpper__toggle {
  position: relative;
  z-index: 10;
}
.style__switch {
  position: absolute;
  margin-left: 2.2rem;
  top: -0.5rem;
}
.warpper__contentBottom {
  display: flex;
  justify-content: space-between;
}
.image__product {
  width: 100%;
  height: 100%;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  object-fit: cover;
  background-color: #78a2cc;
}
.box__stock {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  border-radius: 10px;
}

/* style ของ modal เพิ่มสินค้า */
.image__UploadProduct {
  width: 30vw;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid whitesmoke;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #78a2cc;
}
.text__sub {
  color: red;
}
.warpper__uploadFile {
  position: relative;
}
.btn__uploadFile {
  position: absolute;
  /* top: 1.4rem; */
  width: 10.5rem;
  height: 2.2rem;
  margin-left: -11.5rem;
  opacity: 0;
  cursor: pointer;
  background-color: red;
}
.warpper__imgQRcode {
  display: flex;
  justify-content: center;
}
.QR__code {
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 19.375rem;
  left: 35%;
}

/* style content modal */
.content__Bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.product__selected {
  width: 18rem;
}

/* modal */
.textClick {
  width: 100%;
  margin-top: -2px;
}

.contentSelect {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #b0b0b0;
}
.contentSelect .icons_style {
  background-color: #8dbc8a;
  color: white;
  border: none;
  border-radius: 5px;
}
.btn_style {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #b0b0b0;
  transition: all 0.4s ease-in-out;
  margin: 0 0.5rem;
}
.btn_style:hover {
  background-color: rgb(243, 243, 243);
}
.btn_delete {
  background-color: #dc2625;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
  border: none;
  color: white;
}
.btn_delete:hover {
  background-color: #cb2626;
}

.btn_width {
  width: 45%;
}
@media screen and (max-width: 600px) {
  .content__Bottom {
    display: grid;
  }
  .selected {
    width: 100%;
  }
  .warpper__contentBottom {
    display: grid;
  }
  .pagination_responsive {
    margin-left: -0.6rem;
  }
}
@media screen and (max-width: 960px) {
  .display__responsive {
    justify-content: start !important;
  }
  .wrapper__download {
    align-items: flex-start;
  }
  .style__switch {
    margin-left: 0;
  }
  .image__UploadProduct {
    width: 70vw;
  }
}
</style>
