<template>
  <v-card-text>
    <v-row class="mt-4">
      <v-col md="2" cols="12">
        <v-img
          :aspect-ratio="16 / 9"
          src="../../../assets/images/other/laptop-2298286.svg"
          class="image__product"
        ></v-img>
      </v-col>
      <v-col md="6" cols="12">
        <!-- content 1 -->
        <div v-if="content1">
          <p>(สินค้าไม่มีชื่อ)</p>
          <v-row>
            <v-col md="3" sm="3" cols="12">
              <v-card class="box__stock">
                <p>สต๊อก</p>
                <p>24</p>
              </v-card>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-card class="box__stock">
                <p>ติดจอง</p>
                <p>0</p>
              </v-card>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-card class="box__stock">
                <p>พร้อมขาย</p>
                <p>24</p>
              </v-card>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-card class="box__stock">
                <p>ส่งแล้ว</p>
                <p>3</p>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <!-- end content 1 -->
        <!-- content 2 -->
        <div v-if="!content1">
          <h2>(สินค้าไม่มีชื่อ) - ไม่ระบุแบบ <span class="text_ready">พร้อมขาย</span></h2>
          <p class="mb-0 text_code">SKU: 159</p>
        </div>
        <!-- end content 2 -->
      </v-col>
    </v-row>
    <!-- content 1 -->
    <div v-if="content1">
      <v-simple-table class="mt-4">
        <template v-slot:default>
          <thead>
            <tr v-if="editStock">
              <th class="text-center text-uppercase">
                รหัส
              </th>
              <th class="text-center text-uppercase">
                แบบสินค้า
              </th>
              <th class="text-center text-uppercase">
                ราคา
              </th>
              <th class="text-center text-uppercase">
                สต๊อก
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="iconCode" size="20" v-bind="attrs" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    จำนวนคงเหลือ หลังหักลบกันระหว่าง <br />
                    สินค้าเข้า กับ สินค้าออก
                  </span>
                </v-tooltip>
              </th>
              <th class="text-center text-uppercase">
                +เพิ่มสต๊อก
              </th>
              <th class="text-center text-uppercase">
                หมายเหตุ
              </th>
            </tr>
            <tr v-if="!editStock">
              <th class="text-center">
                รหัส SKU
              </th>
              <th class="text-center">
                รหัส CF
              </th>
              <th class="text-center">
                แบบสินค้า
              </th>
              <th class="text-center">
                ราคา
              </th>

              <th class="text-center">
                สต๊อก
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="iconCode" size="20" v-bind="attrs" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    จำนวนคงเหลือ หลังหักลบกันระหว่าง <br />
                    สินค้าเข้า กับ สินค้าออก
                  </span>
                </v-tooltip>
              </th>
              <th class="text-center">
                ติดจอง
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="iconCode" size="20" v-bind="attrs" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    จำนวนสินค้าแบบดังกล่าวในบิลที่มี <br />
                    สถานะ ยังไม่จ่าย, โอนแล้ว, เตรียมส่ง
                  </span>
                </v-tooltip>
              </th>
              <th class="text-center">
                พร้อมขาย
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="iconCode" size="20" v-bind="attrs" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    จำนวนสต๊อกลบด้วยจำนวน <br />
                    สินค้าติดจอง
                  </span>
                </v-tooltip>
              </th>
              <th class="text-center">
                ส่งแล้ว
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="iconCode" size="20" v-bind="attrs" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    จำนวนสินค้าที่มีความเคลื่อนไหวในสถานะ <br />
                    สินค้าออก เช่น จากจำนวนที่อยู่ในบิล <br />
                    สถานะ ส่งแล้ว
                  </span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody v-if="editStock">
            <tr v-for="item in desserts" :key="item.dessert">
              <td class="text-center clickSwitchContent" @click="switchContent()">{{ item.sku }}</td>
              <td class="text-center clickSwitchContent" @click="switchContent()">
                ไม่ระบุแบบ
              </td>
              <td class="text-center clickSwitchContent" @click="switchContent()">
                {{ item.price }}
              </td>
              <td class="text-center">
                <v-text-field id="stock" outlined dense hide-details></v-text-field>
              </td>
              <td class="text-center">
                <v-text-field id="firstname" outlined dense hide-details></v-text-field>
              </td>
              <td class="text-center">
                <v-text-field id="firstname" outlined dense hide-details></v-text-field>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!editStock">
            <tr v-for="item in desserts" :key="item.dessert" @click="switchContent()" class="clickSwitchContent">
              <td class="text-center">{{ item.sku }}</td>
              <td class="text-center">
                {{ item.cf }}
              </td>
              <td class="text-center">
                ไม่ระบุแบบ
              </td>
              <td class="text-center">
                {{ item.price }}
              </td>

              <td class="text-center">
                {{ item.stock }}
              </td>
              <td class="text-center">
                {{ item.reserve }}
              </td>
              <td class="text-center">
                {{ item.redySell }}
              </td>
              <td class="text-center">
                {{ item.send }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-actions class="d-flex justify-end mt-4 pb-0">
        <!-- button save -->
        <v-btn color="info" class="me-2" @click="clickEditStock()" v-if="!editStock">
          ปรับสต๊อก
        </v-btn>
        <v-btn color="primary" v-if="!editStock" @click="gotoEditPage()">
          แก้ไขสินค้า
        </v-btn>
        <v-btn color="error" outlined class="me-2" @click="clickEditStock()" v-if="editStock">
          ยกเลิก
        </v-btn>
        <v-btn color="primary" v-if="editStock">
          บันทึก
        </v-btn>
      </v-card-actions>
    </div>
    <!-- end content 1 -->
    <!-- content 2 -->
    <div v-if="!content1">
      <hr class="mt-5 mb-3" />
      <div class="d-flex">
        <p class="mb-0 me-5">ต้นทุน/ชิ้น {{ cost }} บาท</p>
        <p class="mb-0 me-5">ต้นทุนรวม {{ costTotal }} บาท</p>
        <p class="mb-0 me-5">ราคา/ชิ้น {{ price }} บาท</p>
        <p class="mb-0">มูลค่ารวม {{ prcieTotal }} บาท</p>
      </div>
      <v-row class="mt-2 mb-2 warpperContent2">
        <v-col md="3" sm="3" cols="12">
          <v-card class="box__stockContent2">
            <p class="mb-3">สต๊อก</p>
            <p class="mb-0">24</p>
          </v-card>
        </v-col>
        <v-col md="3" sm="3" cols="12">
          <v-card class="box__stockContent2">
            <p class="mb-3">ติดจอง</p>
            <p class="mb-0">0</p>
          </v-card>
        </v-col>
        <v-col md="3" sm="3" cols="12">
          <v-card class="box__stockContent2">
            <p class="mb-3">พร้อมขาย</p>
            <p class="mb-0">24</p>
          </v-card>
        </v-col>
        <v-col md="3" sm="3" cols="12">
          <v-card class="box__stockContent2">
            <p class="mb-2">ส่งแล้ว</p>
            <p class="mb-0">3</p>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <!-- tabs -->
        <v-tabs v-model="tab" show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      วันที่
                    </th>
                    <th class="text-left">
                      ประเภท
                    </th>
                    <th class="text-left">
                      จำนวน
                    </th>
                    <th class="text-left">
                      ราคา
                    </th>
                    <th class="text-left">
                      โดย
                    </th>
                    <th class="text-left">
                      หมายเหตุ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in mockData" :key="item.name">
                    <td class="text-left">{{ item.time }}</td>
                    <td class="text-left">{{ item.type }}</td>
                    <td class="text-left">{{ item.quantiny }}</td>
                    <td class="text-left">{{ item.price }}</td>
                    <td class="text-left">{{ item.from }}</td>
                    <td class="text-left">{{ item.note }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>

          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      วันที่
                    </th>
                    <th class="text-left">
                      ประเภท
                    </th>
                    <th class="text-left">
                      จำนวน
                    </th>
                    <th class="text-left">
                      ราคา
                    </th>
                    <th class="text-left">
                      โดย
                    </th>
                    <th class="text-left">
                      หมายเหตุ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in mockData" :key="item.name">
                    <td class="text-left">{{ item.time }}</td>
                    <td class="text-left">{{ item.type }}</td>
                    <td class="text-left">{{ item.quantiny }}</td>
                    <td class="text-left">{{ item.price }}</td>
                    <td class="text-left">{{ item.from }}</td>
                    <td class="text-left">{{ item.note }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-btn color="error" @click="backContent" class="mt-4">
        กลับ
      </v-btn>
    </div>
    <!-- end content 2 -->
  </v-card-text>
</template>

<script>
import { mdiInformation } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [{ title: 'ความเคลื่อนไหวในคลังสินค้า' }, { title: 'บิลติดจอง' }]
    const desserts = [
      {
        dessert: 'Frozen Yogurt',
        sku: 159,
        cf: 23,
        price: 99,
        stock: 24,
        reserve: 0,
        redySell: 24,
        send: 3,
      },
      {
        dessert: 'Ice cream sandwich',
        sku: 237,
        cf: 21,
        price: 189,
        stock: 9,
        reserve: 4,
        redySell: 5,
        send: 6,
      },
      {
        dessert: 'Eclair',
        sku: 262,
        cf: 29,
        price: 239,
        stock: 1,
        reserve: 4,
        redySell: 1,
        send: 2,
      },
      {
        dessert: 'Cupcake',
        sku: 305,
        cf: 22,
        price: 199,
        stock: 5,
        reserve: 4,
        redySell: 4,
        send: 9,
      },
      {
        dessert: 'Gingerbread',
        sku: 356,
        cf: 20,
        price: 99,
        stock: 21,
        reserve: 0,
        redySell: 21,
        send: 4,
      },
    ]
    const mockData = [
      {
        time: 'วันนี้ เวลา 20:36',
        type: 'เข้า',
        quantiny: 2,
        price: 100,
        from: 'แอดมิน',
        note: '-',
      },
      {
        time: 'วันนี้ เวลา 10:59',
        type: 'เข้า',
        quantiny: 1,
        price: 500,
        from: 'แอดมิน',
        note: '-',
      },
      {
        time: 'เมื่อวานนี้ เวลา 15:16',
        type: 'เข้า',
        quantiny: 5,
        price: 50,
        from: 'แอดมิน',
        note: '-',
      },
    ]
    const cost = 100
    const costTotal = 100
    const price = 120
    const prcieTotal = 120

    return {
      desserts,
      tab,
      tabs,
      cost,
      costTotal,
      price,
      prcieTotal,
      mockData,
      icons: {
        mdiInformation,
      },
    }
  },
  data() {
    return {
      editStock: false,
      content1: true,
      length: 2,
      tab: null,
      items: [
        {
          text: 'สินค้า',
          disabled: false,
          href: 'product',
        },
        {
          text: 'สต๊อก',
          disabled: true,
          href: 'stock',
        },
      ],
    }
  },
  methods: {
    clickEditStock() {
      this.editStock = !this.editStock
    },
    gotoEditPage() {
      const editPage = this.$router.resolve({ path: '/pages/edit-product' })
      window.open(editPage.href)
    },
    switchContent() {
      this.content1 = false
    },
    backContent() {
      this.content1 = true
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.image__product {
  width: 100%;
  height: 100%;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  object-fit: cover;
  background-color: #78a2cc;
}
.box__stock {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  border-radius: 10px;
}
.box__stockContent2 {
  padding: 1.5rem 0.5rem;
  text-align: center;
  border-radius: 10px;
  /* height: 5rem; */
}
.iconCode {
  top: -2px;
}
.clickSwitchContent {
  cursor: pointer;
}
.text_ready {
  color: white;
  padding: 5px 10px;
  background-color: #8dbc8a;
  font-size: 16px;
  border-radius: 5px;
}
.text_code {
  font-weight: 700;
  font-size: 18px;
}
.warpperContent2 {
  width: 55%;
}
</style>
