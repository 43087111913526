<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col md="6" cols="12" class="d-flex align-center">
          <h1>เพิ่มสินค้า</h1>
        </v-col>
        <v-col md="6" cols="12" class="d-flex justify-end align-center test__respon">
          <p class="text__toggle">กรอกแบบละเอียด</p>
          <v-switch class="style__switch"></v-switch>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="6" sm="6" cols="12">
          <v-select
            outlined
            dense
            hide-details
            label="อัลบั้มสินค้า:ทั่วไป"
            :items="['+ สร้างอัลบั้มใหม่', 'อัลบั้มสินค้า 1', 'อัลบั้มสินค้า 2', 'อัลบั้มสินค้า 3']"
          ></v-select>
        </v-col>
        <v-col md="6" sm="6" cols="12" class="wrapper__select">
          <v-select
            outlined
            dense
            label="ดึงข้อมูลจากสินค้าอื่น: กรุณาเลือก"
            :items="['(สินค้าไม่มีชื่อ)', '(สินค้าไม่มีชื่อ)', '(สินค้าไม่มีชื่อ)', '(สินค้าไม่มีชื่อ)']"
            class="selected__right"
          ></v-select>
        </v-col>
        <!-- input name product -->
      </v-row>
      <hr class="mb-5" />
      <h3 for="#">ชื่อสินค้า <span class="text__sub">*</span></h3>
      <v-text-field v-model="name" outlined dense hide-details label="ชื่อสินค้า" class="mt-2 mb-3"></v-text-field>
      <!-- รหัสสินค้า -->
      <v-row>
        <!-- รหัส SKU -->
        <v-col md="4" sm="6" cols="12">
          <h3 for="#">
            รหัส SKU
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2 iconCode" v-bind="attrs" v-on="on">
                  {{ icons.mdiInformation }}
                </v-icon>
              </template>
              <span>รหัสแทนสินค้าสำหรับติดตามสต๊อก ควรเป็น A-Z 0-9 <br />ไม่ควรมีภาษาไทย</span>
            </v-tooltip>
          </h3>
          <v-text-field
            v-model="codeSKU"
            outlined
            dense
            hide-details
            label="รหัส SKU"
            placeholder="A01"
            class="mt-2"
          ></v-text-field>
        </v-col>
        <!-- รหัส CF -->
        <v-col md="4" sm="6" cols="12">
          <h3 for="#">
            รหัส CF
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2 iconCode" v-bind="attrs" v-on="on">
                  {{ icons.mdiInformation }}
                </v-icon>
              </template>
              <span>
                รหัสสำหรับ CF สั่งซื้อสินค้าผ่าน Live หรือ โพสต์ <br />รองรับ ก-ฮ A-Z 0-9 เช่น "พริก1" <br />
                *ไม่ควรมีขีดคั่นกลาง หรือ CF นำหน้า*
              </span>
            </v-tooltip>
          </h3>
          <v-text-field
            v-model="codeCF"
            outlined
            dense
            hide-details
            label="รหัส CF"
            placeholder="u12"
            class="mt-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- ราคา -->
      <v-row class="mt-0">
        <v-col md="4" sm="6" cols="12">
          <h3 for="#">
            ราคา
          </h3>
          <v-text-field
            v-model="priceProduct"
            outlined
            dense
            hide-details
            label="ราคา (บาท)"
            class="mt-2"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- จำนวน -->
      <v-row class="mt-0">
        <v-col md="4" sm="6" cols="12">
          <h3 for="#">
            จำนวน
          </h3>
          <div class="d-flex">
            <v-text-field
              v-model="amountProduct"
              outlined
              dense
              hide-details
              label="จำนวน"
              class="mt-2"
              type="number"
            ></v-text-field>
          </div>
        </v-col>
        <v-col class="d-flex align-center">
          <v-switch hide-details class="mt-7 reset-mt" @click="switchData" v-model="toggleSwitch"></v-switch>
          <span class="mt-8 reset-mt">เปิดใช้งานสต๊อก</span>
        </v-col>
      </v-row>

      <!-- content hide -->
      <div class="d-flex" v-if="showData">
        <v-checkbox v-model="checkbox" label="เมื่อสินค้าหมดให้สั่งซื้อได้ปกติ" hide-details></v-checkbox>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2 mt-5 iconCode" v-bind="attrs" v-on="on">
              {{ icons.mdiInformation }}
            </v-icon>
          </template>
          <span>สามารถสั่งสินค้าผ่านช่องทาง CF เปิดบิลอัตโนมัติ <br />ได้ตามปกติแม้สินค้าหมด</span>
        </v-tooltip>
      </div>

      <!-- upload photo -->
      <div class="mt-5">
        <h3 for="#">รูปสินค้า <span class="text__sub">*</span></h3>
        <div class="mt-3 verify">
          <v-icon size="65">{{ icons.mdiImageMultiple }}</v-icon>
          <p class="mt-3">บัตรประชาชน</p>
          <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" />
        </div>
      </div>

      <hr class="mt-5 mb-5" />

      <!-- แสดงสินค้าบน Store -->
      <h2 class="mb-3">แสดงสินค้าบน what you want store</h2>
      <h3>ข้อมูลสินค้า<span class="text__sub">*</span></h3>
      <v-textarea
        label="ข้อมูลสินค้า"
        hide-details
        auto-grow
        outlined
        rows="1"
        row-height="15"
        class="mt-3"
      ></v-textarea>
      <v-row class="mt-0">
        <!-- เมื่อสินค้าหมด -->
        <v-col md="6" sm="6" cols="12">
          <h3 for="#">เมื่อสินค้าหมด <span class="text__sub">*</span></h3>
          <v-select
            v-model="quantity"
            outlined
            dense
            hide-details
            label="เมื่อสินค้าหมด"
            :items="['แสดงบนหน้าร้านว่าสินค้าหมด', 'ให้ติดต่อร้านค้าเท่านั้น']"
            class="mt-2"
          ></v-select>
        </v-col>
      </v-row>

      <!-- ราคาป้าย -->
      <v-row class="mt-0">
        <v-col md="4" sm="6" cols="12">
          <h3 for="#">
            ราคาป้าย
          </h3>
          <div class="d-flex">
            <v-text-field
              v-model="price"
              outlined
              dense
              hide-details
              label="ราคาป้าย"
              class="mt-2"
              type="number"
              placeholder="(ราคาก่อนลด)"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <!-- switch -->
      <div class="d-flex mt-3 mb-3">
        <v-switch hide-details class="mt-0"></v-switch>
        <p class="mb-0 mt-1">แสดงสินค้าบน what you want shop</p>
      </div>
      <hr class="mt-5 mb-5" />

      <!-- สินค้ามีหลายแบบ -->
      <div class="d-flex justify-space-between mt-3 mb-3 reset-style-mobile">
        <div class="d-flex">
          <v-switch hide-details class="mt-0" @click="switch_addData"></v-switch>
          <p class="mb-0 mt-1">สินค้ามีหลายแบบ</p>
        </div>
        <!-- ปรับปรุงแบบสินค้าทั้งหมด -->
        <v-btn @click="gotoAll()" text color="primary" class="me-2" v-if="showAddData">
          <p class="mb-0">ปรับปรุงแบบสินค้าทั้งหมด</p>
        </v-btn>
      </div>
      <div v-if="showAddData">
        <v-row>
          <!-- ชื่อแบบ -->
          <v-col md="3" sm="4" cols="12">
            <h3 for="#">ชื่อแบบ<span class="text__sub">*</span></h3>
            <div class="d-flex">
              <v-text-field
                v-model="name"
                outlined
                dense
                hide-details
                label="ชื่อแบบ"
                class="mt-2"
                type="text"
                placeholder="ห้ามเว้นว่าง"
              ></v-text-field>
            </div>
          </v-col>
          <!-- SKU Code -->
          <v-col md="3" sm="4" cols="12">
            <h3 for="#">
              รหัส SKU
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 iconCode" size="20" v-bind="attrs" v-on="on">
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>รหัสแทนสินค้าสำหรับติดตามสต๊อก ควรเป็น A-Z 0-9 <br />ไม่ควรมีภาษาไทย</span>
              </v-tooltip>
            </h3>
            <div class="d-flex">
              <v-text-field
                v-model="sku_code"
                outlined
                dense
                hide-details
                label="รหัส SKU"
                class="mt-2"
                type="text"
                placeholder="A01"
              ></v-text-field>
            </div>
          </v-col>
          <!-- CF Code -->
          <v-col md="3" sm="4" cols="12">
            <h3 for="#">
              รหัส CF
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 iconCode" v-bind="attrs" v-on="on">
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>
                  รหัสสำหรับ CF สั่งซื้อสินค้าผ่าน Live หรือ โพสต์ <br />รองรับ ก-ฮ A-Z 0-9 เช่น "พริก1" <br />
                  *ไม่ควรมีขีดคั่นกลาง หรือ CF นำหน้า*
                </span>
              </v-tooltip>
            </h3>
            <div class="d-flex">
              <v-text-field
                v-model="cf_code"
                outlined
                dense
                hide-details
                label="รหัส CF"
                class="mt-2"
                type="text"
                placeholder="u12"
              ></v-text-field>
            </div>
          </v-col>
          <!-- ราคา -->
          <v-col md="3" sm="4" cols="12">
            <h3 for="#">ราคา</h3>
            <div class="d-flex">
              <v-text-field
                v-model="price_add"
                outlined
                dense
                hide-details
                label="ราคา (บาท)"
                class="mt-2"
                type="number"
                placeholder="0.00"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="4" cols="12" class="pt-0">
            <h3 for="#">จำนวน</h3>
            <div class="d-flex">
              <v-text-field
                v-model="amount_add"
                outlined
                dense
                hide-details
                label="จำนวน"
                class="mt-2"
                type="number"
                placeholder="0"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <hr class="mt-5 mb-5" />
        <!-- เพิ่มแบบสินค้า -->
        <div class="text-center">
          <v-btn @click="gotoAll()" text color="primary">
            <v-icon color="primary" class="me-2">{{ icons.mdiPlusCircle }}</v-icon>
            <p class="mb-0">เพิ่มแบบสินค้า</p>
          </v-btn>
        </div>
      </div>

      <!-- button save -->
      <div class="d-flex justify-end mt-5">
        <v-btn color="error" outlined class="me-2">
          ยกเลิก
          <v-icon class="ml-2">
            {{ icons.mdiCloseThick }}
          </v-icon>
        </v-btn>
        <v-btn color="primary">
          บันทึก
          <v-icon class="ml-2">
            {{ icons.mdiContentSave }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCloudUploadOutline,
  mdiCloseThick,
  mdiContentSave,
  mdiImageMultiple,
  mdiInformation,
  mdiPlusCircle,
} from '@mdi/js'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    product_type_id: {
      type: Number,
      required: true,
    },
    brand_id: {
      type: Number,
      required: true,
    },
    model_id: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    product_price1: {
      type: Number,
      required: true,
    },
    product_price2: {
      type: Number,
      required: true,
    },
    product_price3: {
      type: Number,
      required: true,
    },
    product_price4: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiCloudUploadOutline,
        mdiCloseThick,
        mdiContentSave,
        mdiImageMultiple,
        mdiInformation,
        mdiPlusCircle,
      },
    }
  },
  data() {
    return {
      codeSKU: '',
      codeCF: '',
      priceProduct: 0,
      amountProduct: 0,
      toggleSwitch: false,
      showData: false,
      showAddData: false,
    }
  },
  methods: {
    switchData() {
      this.showData = !this.showData
    },
    switch_addData() {
      this.showAddData = !this.showAddData
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.text__sub {
  color: red;
}
.text__toggle {
  margin-bottom: 0 !important;
  margin-right: 0.8rem;
}
.wrapper__select {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.selected__right {
  width: 60%;
}
.warpper__uploadFile {
  position: relative;
}
.btn__uploadFile {
  position: absolute;
  /* top: 1.4rem; */
  width: 10.5rem;
  height: 2.2rem;
  margin-left: -11.5rem;
  opacity: 0;
  cursor: pointer;
  background-color: red;
}
.image__product {
  width: 30vw;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid whitesmoke;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #78a2cc;
}
.text__checkbox {
  color: red;
}
.quantity__input {
  width: 30%;
}
.selected__content {
  width: 30%;
}
.width__textArea {
  width: 60%;
}
.size__icons {
  font-size: 20rem;
}
.warpper__imgQRcode {
  display: flex;
  justify-content: center;
}
.QR__code {
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 19.375rem;
  left: 35%;
}
.verify {
  background-color: #f6f6f6;
  width: 18rem;
  height: 15rem;
  border: 2px dashed #cccccc;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.verify:hover {
  background-color: #dddddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.uploadFile {
  position: absolute;
  top: 0;
  background-color: red;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.iconCode {
  top: -2px;
  color: #395b64;
}
@media screen and (max-width: 960px) {
  .test__respon {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    justify-content: start !important;
    margin-top: -2rem !important;
    margin-bottom: -1rem !important;
  }
  .wrapper__select {
    align-items: flex-start;
  }
  .image__product {
    width: 70vw;
  }
  .selected__right {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .reset-mt {
    margin-top: 0 !important;
  }
  .reset-style-mobile p {
    font-size: 14px;
  }
}
</style>
