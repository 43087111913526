<template>
  <v-card flat class="wapper__content">
    <v-card-text class="content__main">
      <div class="wapper__contentFile">
        <v-icon size="50"> {{ icons.mdiImagePlus }} </v-icon>
        <h3 class="mt-2">เลือกไฟล์</h3>
        <p>PNG, JPG or JPEG</p>
      </div>
      <div warpper__uploadFile>
        <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="btn__uploadFile" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiImagePlus } from '@mdi/js'

export default {
  setup() {
    const productSelected = ['ทั่วไป', '+ เพิ่มอัลบั้มสินค้าใหม่']

    return {
      icons: {
        mdiImagePlus,
      },
      productSelected,
    }
  },
}
</script>

<style scoped>
.wapper__content {
  padding: 1rem;
}
.wapper__contentFile {
  text-align: center;
  padding: 20px;
}
.wapper__contentFile h3 {
  color: #8dbc8a !important;
}
.content__main {
  border: 2px dashed #928d99;
}
.warpper__uploadFile {
  position: relative;
}
.btn__uploadFile {
  position: absolute;
  top: 1.4rem;
  width: 95%;
  height: 12rem;
  margin-left: -1.5rem;
  opacity: 0;
  cursor: pointer;
  background-color: red;
}
</style>
