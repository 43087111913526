<template>
  <v-card flat>
    <v-card-text class="text-center padding__content">
      <h3>
        กรุณาเตรียมไฟล์ข้อมูลสินค้าตามข้อมูล <a href="https://www.google.com/" target="_blank">ดังนี้</a> เพื่อดำเนินการ
        <a href="https://www.google.com/" target="_blank">ติดต่อทีมงาน</a>
      </h3>
    </v-card-text>
  </v-card>
</template>

<script>
export default {}
</script>

<style scoped>
.padding__content {
  padding: 5rem 0 !important;
}
.padding__content a {
  text-decoration: none;
}
</style>
