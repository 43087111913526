<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-breadcrumbs :items="items" class="pb-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <ManageProduct />
      </v-tab-item>

      <v-tab-item>
        <AddProduct />
      </v-tab-item>

      <v-tab-item>
        <AlbumProduct />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import ManageProduct from './ManageProduct.vue'
import AddProduct from './AddProduct.vue'
import AlbumProduct from './AlbumProduct.vue'

export default {
  components: {
    ManageProduct,
    AddProduct,
    AlbumProduct,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'จัดการสินค้า', icon: mdiCartOutline },
      { title: 'เพิ่มสินค้า', icon: mdiCartPlus },
      { title: 'อัลบั้มสินค้า', icon: mdiMore },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiCartOutline,
        mdiCartPlus,
        mdiMore,
      },
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'สต๊อก',
        disabled: true,
        href: 'stock',
      },
    ],
  }),
}
</script>
