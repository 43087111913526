<template>
  <v-card flat>
    <v-card-text>
      <!-- Filter -->
      <v-row>
        <v-col md="6" sm="6" cols="12">
          <v-select dense outlined hide-details label="ตัวกรอง" :items="filter" class="select__filter"></v-select>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <v-select dense outlined hide-details label="เรียงตาม" :items="sort"></v-select>
        </v-col>
      </v-row>
      <!-- Card Content -->
      <v-row>
        <v-col md="4" sm="6" cols="12" v-for="item in product" :key="item.id">
          <v-card class="warpper__content">
            <div class="content__btnSetting">
              <v-img :src="item.img" height="250"></v-img>
              <v-btn icon @click="clickSetting" class="btn__settting" v-model="item.id">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
              <v-card v-show="isSettingCard" class="setting__card">
                <span
                  >ไม่สามารถแก้ไขได้<v-icon class="testcolor">{{ icons.mdiAlertCircle }}</v-icon>
                </span>
              </v-card>
            </div>
            <v-card-title class="text__head">
              <p>{{ item.description }}</p>
              <p>({{ quantityProduct }})</p>
            </v-card-title>
            <v-card-text class="d-flex">
              <v-switch class="style__switch" hide-details v-model="product.id"></v-switch>
              <p>แสดงบนหน้าร้าน</p>
            </v-card-text>
            <!-- <v-card-actions class="dense">
          <v-btn color="primary" text>
            Details
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="isCardDetailsVisible = !isCardDetailsVisible">
            <v-icon>{{ isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="isCardDetailsVisible">
            <v-divider></v-divider>
            <v-card-text>
              I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for
              sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey,
              you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to
              escape.
            </v-card-text>
          </div>
        </v-expand-transition> -->
          </v-card>
        </v-col>
      </v-row>

      <hr class="mt-5" />
      <div class="mt-4 warpper__contentBottom">
        <p>แสดง 1 ถึง {{ page }} จาก {{ leghtPage }} ผลลัพธ์</p>
        <div>
          <!-- <a @click="clickFirstPage">หน้าแรก</a> -->
          <v-pagination v-model="page" :length="leghtPage" :total-visible="5"></v-pagination>
          <!-- <a @click="clickLastPage">หน้าสุดท้าย</a> -->
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiDotsVertical, mdiAlertCircle } from '@mdi/js'

export default {
  props: {
    show: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const isCardDetailsVisible = false
    const isSettingCard = false
    const filter = ['สินค้าทั้งหมด', 'สินค้าที่ไม่แสดงบนหน้าร้าน', 'สินค้าที่แสดงบนหน้าร้าน']
    const sort = [
      'ขายได้มากที่สุด',
      'ขายได้น้อยที่สุด',
      'สต๊อกเหลือมากที่สุด',
      'สต๊อกเหลือน้อยที่สุด',
      'ติดจองมากที่สุด',
      'ติดจองน้อยที่สุด',
    ]
    const product = [
      {
        id: 1,
        img: 'https://cdn.pixabay.com/photo/2021/09/26/09/55/seal-6656983_1280.jpg',
        description: 'ทั่วไป',
      },
      {
        id: 2,
        img: 'https://cdn.pixabay.com/photo/2022/06/25/23/41/ladybug-7284337_1280.jpg',
        description: 'พิเศษ',
      },
    ]

    return {
      isCardDetailsVisible,
      filter,
      sort,
      product,
      isSettingCard,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiDotsVertical,
        mdiAlertCircle,
      },
    }
  },
  data() {
    return {
      quantityProduct: 1,
      page: 1,
      leghtPage: 10,
    }
  },
  methods: {
    clickSetting() {
      this.isSettingCard = !this.isSettingCard
    },
  },
}
</script>

<style scoped>
.warpper__content {
  border-radius: 15px;
  overflow: hidden;
  max-width: 19.375rem;
}
.content__btnSetting {
  position: relative;
}
.btn__settting {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.setting__card {
  position: absolute;
  top: 3rem;
  right: 0.5rem;
  padding: 10px;
}
.testcolor {
  color: red;
  margin-left: 0.5rem;
  top: -2px;
}
.text__head {
  display: flex;
  justify-content: space-between;
}
.style__switch {
  margin-top: 0;
  padding-top: 0;
}
.warpper__contentBottom {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .warpper__contentBottom {
    display: grid;
  }
}
</style>
